<template>
  <div class="table-content">
    <v-data-table
      class="elevation-0"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      @update:options="paginate"
    >
        <template v-slot:[`item.content`]="{ item }">
          <content-viewer :item-id="item.id" :content="item.content"></content-viewer>
        </template>
    </v-data-table>

    <!-- <div class="text-center">
            <v-pagination
            v-model="page"
            :total-visible="15"
            :length="6"
            ></v-pagination>
        </div> -->
  </div>
</template>

<script>
export default {
  components: {
    ContentViewer: () => import("./ContentViewer")
  },
  props: {
    items: {
      type: Array
    },
    total: {
      type: Number
    }
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 15
    },
    params: {
      page: 1,
      per_page: 15
    },
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title"
      },
      {
        text: "Content",
        value: "content"
      }
    ]
  }),
  methods: {
    paginate() {
      this.params.page = this.options.page;
      this.params.per_page = this.options.itemsPerPage;
      this.$emit("update", this.params);
    }
  }
};
</script>
